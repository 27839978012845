<template>
  <div class="box">
    <p class="title">{{ distance }}</p>
    <div id="content" @click="clickBox"></div>
    <button @click="goBack" class="goLine">退出导航</button>
    <div v-if="showLoadingText" class="loading-text">
      <span>定位信号弱 预计剩余{{LoadingNumber}}秒</span>
    </div>

    <div v-if="this.line.length" class="residue">
      <p style="coloe: #fff;"> 
        <span>距离{{this.line[this.line.length - 1].direct == '9'? '下一层':this.line[this.line.length - 1].direct == '10' ? '上一层': '目的地'}}大约还有</span>
        <span style="color: red">{{ residue }}</span>
        <span>米</span>
      </p>
      <p style="coloe: #fff;"> 
        {{aimPark.park_name}} {{aimPark.floor_id}}层 {{aimPark.area_id}}区 {{aimPark.park_no}}车位
      </p>  
    </div>
    <!-- <div class="tab_line">
      <p :class="$route.query.type==3? 'active': ''" @click="tabType(3)">找充电桩</p>
      <p :class="$route.query.type==2? 'active': ''" @click="tabType(2)">人找车</p>
      <p :class="$route.query.type==1? 'active': ''" @click="tabType(1)">车找停车位</p>
      <p :class="$route.query.type==4? 'active': ''" @click="tabType(4)">找厕所</p>
    </div>
     -->
    <div class="mask-popup" v-if="showVoiceMsg">
      <div class="mask-content">
        <div class="header">提示</div>
        <div class="content">您是否需要语音播报</div>
        <div style="display: flex; justify-content: space-around; padding: 0px 40px;" class="footer">
          <span style="width: 100%;" @click="onVoice(true)">需要</span>
          <span style="width: 100%;" @click="onVoice(false)">不需要</span>
        </div>
      </div>
    </div>
    <div class="mask-popup" v-if="showPopup">
      <div class="mask-content">
        <div class="header">警告</div>
        <div class="content">您已偏离路线</div>
        <div @click="onRefresh" class="footer">重新规划</div>
      </div>
    </div>
    <div class="mask-popup" v-if="showReload">
      <div class="mask-content">
        <div class="header">提示</div>
        <div class="content">检测目标车位被占</div>
        <div @click="onRefresh" class="footer">重新规划</div>
      </div>
    </div>
    <div class="mask-popup" v-if="showClose">
      <div class="mask-content">
        <div class="header">提示</div>
        <div class="content">您已到达车位附近 导航完成</div>
        <div @click="goBack" class="footer">关闭导航</div>
      </div>
    </div>
    <div class="mask-popup" v-if="showDevice">
      <div class="mask-content">
        <div class="header">提示</div>
        <div style="padding:20px 0px;" class="content">
          <p style="line-height: 30px;">为了您的导航体验.</p>
          <p style="line-height: 30px;">系统将申请访问陀螺仪</p>
        </div>
        <div @click="requestPermission" class="footer">确认</div>
      </div>
    </div>
    <div class="mask-popup" v-if="showLoad">
      <div class="mask-content">
        <div class="header">提示</div>
        <div class="content">正在加载楼层模型...</div>
      </div>
    </div>
    <div class="mask-popup" v-if="showLoading">
      <div class="mask-content">
        <div class="header">提示</div>
        <div style="padding:20px 0px;" class="content">
          <p style="line-height: 30px;">定位信号搜索中...</p>
          <p v-if="checkFloor==false" style="line-height: 30px;">请确认您已到达停车场</p>
        </div>
      </div>
    </div>

    <div class="mask-popup" v-if="showError">
      <div class="mask-content">
        <div class="header">提示</div>
        <div  style="padding: 20px; line-height: 30px; font-size: 18px;">{{showErrorText}}</div>
        <div @click="goBack" class="footer">关闭导航</div>
      </div>
    </div>

  </div>
</template>

<script>
var u = navigator.userAgent;

var isAndroid = u.indexOf('Android') > -1 || u.indexOf('adr') > -1; //android终端

var isIos = !!u.match(/\(i[^;]+;( u;)? cpu.+mac os x/); //ios终端


import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import axios from 'axios';
import {mapState,mapActions} from 'vuex';
let numberTime = 0
let timerInt = null
let timerLoad = null
let voiceMsg = null

if(isIos){
  voiceMsg = new window.SpeechSynthesisUtterance()
}else{
  voiceMsg = {}
}


export default {
  name: 'App',
  data() {
    return {
      pageInit:false,
      oIndex: 0,

      showError:false,
      showErrorText:'',

      showVoiceMsg:true,
      voiceMsgStatus:0, // 0 初始值 1同意 2拒绝

      showPopup: false,
      showClose: false,
      showLoad: false,
      showReload:false,

      showLoading:false,

      checkFloor:false,

      showDevice:false,
      deviceStatus:false,

      showLoadingText:false,
      LoadingNumber:15,
      residue: 0,

      distance: '',

      park_id:'',
      aimPark:{},
      line: [],
      list: [],

      lineList: [],

      coordinate: [
        '到达目的地',
        '直行',
        '右前方',
        '右转',
        '右后方',
        '掉头',
        '左后方',
        '左转',
        '左前方',
        '前往下一层',
        '前往上一层',
      ],
    };
  },
  components: {},
  computed: {
    ...mapState({
      position: state => state.position 
    }),
    currentRouteType: function () {
      return this.$route.query.type;
    },
  },
  mounted() {
    if(isAndroid){
      this.onVoice(false)
    }
  },

  methods: {
    onVoice(bl){
      if(bl){
        this.voiceMsgStatus = 1;
        if(window.speechSynthesis.speaking == false && isIos ){
          window.speechSynthesis.cancel();
          voiceMsg.text = '定位信号搜索中'
          window.speechSynthesis.speak(voiceMsg);
        }
      }else{
        this.voiceMsgStatus = 2;
      }
      this.showLoading = true
      this.showVoiceMsg = false
    },
    LoadingTime(){
      clearInterval(timerLoad)
     
      let line_id = this.lineList[this.oIndex].line_id;
        
      let user_id = this.$route.query.user_id;
        
      axios.post('https://aipark.meilibaobiao.com/aipark/V1.0.0/insert_xunlu_start_flag',{data: {
        line_id,
        user_id
      }})
      this.showLoadingText = true;

      timerLoad = setInterval(()=>{
        if(this.LoadingNumber>0){
          this.LoadingNumber = this.LoadingNumber-1
        }else{
          this.LoadingNumber = 15
          this.showLoadingText = false
          clearInterval(timerLoad)
        }
      },1000)
    },
    init(){
      let type = this.$route.query.type;
      let ent_id = this.$route.query.ent_id;
      let build_id = this.$route.query.build_id;
      let floor_id = this.$route.query.floor_id;
      let area_id = this.$route.query.area_id;
      let car_no = this.$route.query.car_no;
      let user_id = this.$route.query.user_id;

      

      document.title = '正在为您导航';
      let scene = new THREE.Scene();
      scene.background = new THREE.Color(0xaaaaaa);
      let camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000,
      );
      let renderer = new THREE.WebGLRenderer();
      renderer.setSize(window.innerWidth, window.innerHeight);
      let app = document.getElementById('content');
      app.appendChild(renderer.domElement);

      //加载场景控制插件
      // let controls = new OrbitControls(camera, renderer.domElement);
      // // controls.enableDamping = true;
      // controls.enableZoom = false;
      // controls.autoRotate = false;
      // controls.enableRotate = false;
      // // controls.maxPolarAngle = 0;
      // // controls.enableRotate = false;
      // controls.autoRotateSpeed = 3;
      // controls.enablePan = false;
      let {xMillimeter,yMillimeter,x,y,floorNo} = this.position
      camera.position.set(x, 400, y);
      camera.lookAt(x, 3, y);

      this.scene = scene;
      this.camera = camera;
      // this.controls = controls;

      /**
       * 光源设置
       */
      //点光源
      var point = new THREE.PointLight(0xffffff);
      point.position.set(0, 200, 0); //点光源位置
      scene.add(point); //点光源添加到场景中
      //环境光
      var ambient = new THREE.AmbientLight(0xdddddd);
      scene.add(ambient);
      
      axios
        .post('https://aipark.meilibaobiao.com/aipark/V1.0.0/xunlu', {
          data: {
            x: `${xMillimeter}`,
            y: `${yMillimeter}`,
            local_floor_id:floorNo,
            type,
            ent_id,
            build_id,
            floor_id,
            // user_id:'test',
            //Floor2
            car_no,
            area_id,
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.showLoading=false;
            this.showLoad = true;
            if(this.voiceMsgStatus == 1&&window.speechSynthesis.speaking == false&&isIos ){
              window.speechSynthesis.cancel();
              voiceMsg.text = '正在加载楼层模型 请稍候'
              window.speechSynthesis.speak(voiceMsg);
            }
            this.line = this.getLineNumber(
              response.data.body.data[this.oIndex].point_list,
            );
            this.list = this.getListNumber(
              response.data.body.data[this.oIndex].point_list,
            );
            this.model_url = response.data.body.data[this.oIndex].model_url;
            this.lineList = response.data.body.data;
            this.park_id = response.data.body.park_id;
            this.aimPark = response.data.body.aimPark;
            axios
              .post(
                'https://aipark.meilibaobiao.com/aipark/V1.0.0/query_park_status_list',
                {
                  data: {
                    ent_id,
                    build_id,
                    floor_id:this.position.floorNo,
                    area_id,
                  },
                },
              )
              .then((response) => {
                if (response.data.code == 200) {
                  this.parkList = response.data.body.data;
                  this.createBox(scene);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }else{
            this.showLoading = false
            this.showError = true;
            this.showErrorText = response.data.msg;
            window.speechSynthesis.cancel();

            
          }
        })
        .catch((error) => {
          console.log(error);
          this.showLoading = false
          this.showError = true;
          this.showErrorText = '路线规划失败';
          window.speechSynthesis.cancel();
        });

      //渲染场景
      let animate = () => {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      };
      animate();
    },
    onRefresh() {
      location.reload();
    },
    goBack() {
      window.wx.miniProgram.navigateBack();
    },
    tabType(v) {
      this.$router.push({ path: '/', query: { type: v } });
    },
    requestPermission(){
      this.showDevice = false
      window.DeviceOrientationEvent.requestPermission().then(state => {
        switch (state) {
          case "granted":
            // you can do something
            this.deviceStatus = true
            this.LoadingTime()
            break;
          case "denied":
            this.deviceStatus = false
            this.LoadingTime()
            break;
          case "prompt":
            this.LoadingTime()
            break;
        }
      });
    },
    //生成随机盒子模型
    createBox(scene) {
      var loader = new GLTFLoader();
      loader.load(this.model_url, (obj) => {
        obj.scene.traverse(function (child) {});
        scene.add(obj.scene);
        this.parkList.forEach((item, index) => {
          let mm = scene.getObjectByName(item.park_id);

          if(mm){
            if (item.status == 0) {
              mm.children[1].material = new THREE.MeshBasicMaterial({
                color: '#00B554',
              });
            } else if (item.status == 1) {
              mm.children[1].material = new THREE.MeshBasicMaterial({
                color: '#E60012',
              });
            }else if (item.status == 2) {
              mm.children[1].material = new THREE.MeshBasicMaterial({
                color: 'yellow',
              });
            }
          }
          clearInterval(timerInt)
          timerInt = setInterval(()=>{
            this.updataPosition()
          },800)
        });
       
        let timer = 0;
        let a = 0;
        this.showLoad = false;
        if(this.voiceMsgStatus == 1&&isIos){
          voiceMsg.text = '您好 导航已开始'
          window.speechSynthesis.speak(voiceMsg);
        }

        if(window.DeviceOrientationEvent&&window.DeviceOrientationEvent.requestPermission){
          if(this.deviceStatus==false){
            this.showDevice = true
          }
        }else{
          this.LoadingTime()
        }
        this.updataPosition()
        function PointToLineDistance(x, y, x1, y1, x2, y2) {
          //三角形面积
          var area = Math.abs(
            0.5 * (x1 * y2 + x2 * y + x * y1 - x2 * y1 - x * y2 - x1 * y),
          );
          //三角形底边长
          var bottom = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
          //高即为所求的垂直距离
          var height = (area / bottom) * 2;
          return height;
        }
        function getAngleByThreeP(last, next, next1) {
          let a_b_x = last[0] - next[0];
          let a_b_y = last[2] - next[2];
          let c_b_x = next1[0] - next[0];
          let c_b_y = next1[2] - next[2];
          let ab_mul_cb = a_b_x * c_b_x + a_b_y * c_b_y;
          let dist_ab = Math.sqrt(a_b_x * a_b_x + a_b_y * a_b_y);
          let dist_cd = Math.sqrt(c_b_x * c_b_x + c_b_y * c_b_y);
          let cosValue = ab_mul_cb / (dist_ab * dist_cd);
          return Math.acos(cosValue);
        }
        const getAngle = (last, next, next1) => {
          let x1 = last[0] - next[0];
          let x2 = last[2] - next[2];
          let y1 = next1[0] - next[0];
          let y2 = next1[2] - next[2];
          const dot = x1 * x2 + y1 * y2;
          const det = x1 * y2 - y1 * x2;
          const angle = (Math.atan2(det, dot) / Math.PI) * 180;
          return Math.round(angle + 360) % 360;
        };
        let list = this.list;
        
        // 没有走的路线
        var material = new THREE.LineBasicMaterial({
          color: 0xffff00,
          linewidth: 6,
          linecap: 'butt',
          opacity: 0.8,
        });

        var geometry = new THREE.BufferGeometry();
        geometry.setFromPoints(
          this.line.map((item) => new THREE.Vector3(item.x, 0.3, item.y)),
        );

        var line = new THREE.Line(geometry, material);
        line.name = 'line';
        scene.add(line);

        // 走过的路线
        var material = new THREE.LineBasicMaterial({
          color: 0xffff00,
          linewidth: 4,
          linecap: 'butt',
          opacity: 0.6,
        });

        var geometry = new THREE.BufferGeometry();
        geometry.setFromPoints(
          this.line.map((item) => new THREE.Vector3(item.x, 0.3, item.y)),
        );

        var afterLine = new THREE.Line(geometry, material);
        afterLine.name = 'afterLine';
        scene.add(afterLine);


        

        var texture = new THREE.TextureLoader().load(
          'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-0382e5c9-4d8d-48ce-ae50-8c8dd965f1ae/deaa39ea-9f05-4bfc-9a18-07d013132474.png',
        );
        // 创建精灵材质对象SpriteMaterial
        var spriteMaterial = new THREE.SpriteMaterial({
          transparent: true,
          map: texture, //设置精灵纹理贴图
        });
        // 创建精灵模型对象，不需要几何体geometry参数
        var sprite = new THREE.Sprite(spriteMaterial);
        // 控制精灵大小，比如可视化中精灵大小表征数据大小
        sprite.scale.set(6, 6, 0); //// 只需要设置x、y两个分量就可以
        sprite.position.set(
          Number(this.line[this.line.length - 1].x),
          2,
          Number(this.line[this.line.length - 1].y),
        );
        sprite.name = 'end';
        scene.add(sprite);
      });
      this.scene = scene;
    },
    updataPosition(){
      let that = this;
      let {xMillimeter,yMillimeter,x,y} = this.position
      // axios.post('https://aipark.meilibaobiao.com/aipark/V1.0.0/test_logs',{data: {
      //   user_id:this.$route.query.user_id,
      //   value:JSON.stringify(this.position),
      //   type:'2'
      // }})
      // let user = this.scene.getObjectByName('user');
      // if(user){
        // user.position.set(x, 3, y);
        // this.camera.lookAt(user.position);
      // }else{
        // var circleP = new THREE.Mesh(geometryP2, materialP2);
        // circleP.name = 'user';
        // circleP.position.set(x, 3, y);
        
        // this.camera.lookAt(circleP.position);
      // }
      // this.camera.position.set(x, 300, y);


      
          
      // console.log('12345')
      
      if(this.lineList[this.oIndex]){
        // console.log(this.lineList[this.oIndex].point_list[0].floor_id, this.position,'floorNo')
        if(this.lineList[this.oIndex].point_list[0].floor_id != this.position.floorNo){
          this.showLoading = true
          this.checkFloor = true
          return
        }else{
          this.checkFloor = false
          this.showLoading = false
        }
        
        if(this.lineList[this.oIndex].point_list.length<=3){
        
          document.title = '导航已结束';
          this.showClose = true;
          if(this.voiceMsgStatus == 1&&isIos){
            window.speechSynthesis.cancel();
            voiceMsg.text = '您好 导航已结束'
            window.speechSynthesis.speak(voiceMsg);
          }
        }
        let ent_id = this.$route.query.ent_id;
        let build_id = this.$route.query.build_id;
        let floor_id = this.$route.query.floor_id;
        let area_id = this.$route.query.area_id;
        let line_id = this.lineList[this.oIndex].line_id;
        let local_floor_id = this.position.floorNo;
        let user_id = this.$route.query.user_id;
        let params = {x:`${xMillimeter}`,y:`${yMillimeter}`,user_id,local_floor_id,ent_id,build_id,floor_id,area_id,park_id:this.park_id,line_id}
        axios.post('https://aipark.meilibaobiao.com/aipark/V1.0.0/query_car_park_if_used',{data: params})
        .then((response) => {
          if (response.data.code == 200) {
            // console.log(response)
            let {toEndDistance,nextForkType,toNextForkDistance,status,lastPoint,nextPoint,speakFlag} = response.data.body.data
            this.residue= ((+toEndDistance) / 1000).toFixed();
            toNextForkDistance = ((+toNextForkDistance) / 1000).toFixed();
            nextForkType = nextForkType== null ? '' : Number(nextForkType)

            

            let px = Number(nextPoint.x) / 304.8,
                py = -Number(nextPoint.y) / 304.8;

            // console.log(nextPoint,this.line)
            let line2 = this.scene.getObjectByName('line');
            let afterLine = this.scene.getObjectByName('afterLine');

            
            let blines = []
            let tIndex = this.line.findIndex(item=> item.point_id == nextPoint.point_id)
            this.line.forEach((item,index)=>{
              if(index<=tIndex){
               
              }else{
                blines.push(item)
                
              }
            })
            if(line2&&line2.geometry){
              line2.geometry.setFromPoints(
                blines.map((item) => new THREE.Vector3(item.x, 0.3, item.y)),
              );
            }
            
            

            let user = this.scene.getObjectByName('user');
            if(!user){
              var geometry = null;
              var imgUrl = null;
              
              imgUrl =  './dir.png'
              geometry = new THREE.PlaneGeometry(10, 10); //矩形平面
              
              
              // TextureLoader创建一个纹理加载器对象，可以加载图片作为几何体纹理
              var textureLoader = new THREE.TextureLoader();
              // 执行load方法，加载纹理贴图成功后，返回一个纹理对象Texture
              textureLoader.load(imgUrl, (texture)=>{
                var material = new THREE.MeshLambertMaterial({
                  transparent: true,
                  opacity: 1,
                  // 设置颜色纹理贴图：Texture对象作为材质map属性的属性值
                  map: texture,//设置颜色贴图属性值
                }); //材质对象Material
                var circleP = new THREE.Mesh(geometry, material); //网格模型对象Mesh
                
                circleP.name = 'user';
                circleP.position.set(px, 13, py);
                
                circleP.rotateX(-1.63);
                this.camera.position.set(px, 150, py);
                this.scene.add(circleP);
                // circleP.rotateZ((270 * Math.PI) / 180);
              })
             
            }
            
            
           let rx = this.getBinary(+lastPoint.x - +nextPoint.x);
           let ry = this.getBinary(-Number(lastPoint.y) - -Number(nextPoint.y));
           user = this.scene.getObjectByName('user');
            this.camera.lookAt(user.position);
           
            // rx = -1
            // ry = -1
           this.onRotateZ(this.camera, rx, ry);
           
            if(user){
              user.position.set(px, 3, py);
              this.camera.position.set(px, 150, py);
              this.onUserRotateZ(user, rx, ry);
             
            }
           
          //  this.onUserRotateZ(user, rx, ry);
           
           
           
            // this.camera.lookAt(circleP.position);
            

            if(this.coordinate[nextForkType]){
              if (nextForkType == 0) {
                this.distance = `${toNextForkDistance}米后${
                  this.coordinate[nextForkType]
                }`;
              } else {
                if (nextForkType % 2 === 0) {
                  this.distance = `${toNextForkDistance}米后向${
                    this.coordinate[nextForkType]
                  }行驶`;
                }
                if (nextForkType % 2  === 1) {
                  this.distance = `${toNextForkDistance}米后${
                    this.coordinate[nextForkType]
                  }`;
                }
              }
            }
            if(speakFlag=='1'){
              if(this.voiceMsgStatus == 1&&isIos){
                window.speechSynthesis.cancel();
                voiceMsg.text = this.distance
                window.speechSynthesis.speak(voiceMsg);
              }
            }
            
            if(status == 1){
              this.showReload = true
              clearInterval(timer); // 结束
              if(this.voiceMsgStatus == 1&&isIos){
                window.speechSynthesis.cancel();
                voiceMsg.text = '检测目标车位被占 请重新导航'
                window.speechSynthesis.speak(voiceMsg);
              }
              
            }
            if(status == 2){
              this.showPopup = true
              clearInterval(timer); // 结束
              if(this.voiceMsgStatus == 1&&isIos){
                window.speechSynthesis.cancel();
                voiceMsg.text = '您已偏离路线 请重新导航'
                window.speechSynthesis.speak(voiceMsg);
              }
            }
            
            
            let line = this.scene.getObjectByName('line');
            let aline = this.scene.getObjectByName('afterLine');
            let end = this.scene.getObjectByName('end');
            let user2 = this.scene.getObjectByName('user');
            // console.log(nextForkType,'nextForkType')
            if (status == 3) {
              this.scene.remove(line);
              this.scene.remove(aline);
              this.scene.remove(end);
              document.title = '导航已结束';
              this.showClose = true;
              if(this.voiceMsgStatus == 1&&isIos){
                window.speechSynthesis.cancel();
                voiceMsg.text = '您好 导航已结束'
                window.speechSynthesis.speak(voiceMsg);
              }

            } else if (status == 4) {
              if(nextForkType == 9){
                document.title = '正在前往下一层';
                if(this.voiceMsgStatus == 1&&isIos){
                  window.speechSynthesis.cancel();
                  voiceMsg.text = '正在前往下一层'
                  window.speechSynthesis.speak(voiceMsg);
                }
              }
              if(nextForkType == 10){
                document.title = '正在前往上一层';
                if(this.voiceMsgStatus == 1&&isIos){
                  window.speechSynthesis.cancel();
                  voiceMsg.text = '正在前往上一层'
                  window.speechSynthesis.speak(voiceMsg);
                }
              }
              
              this.showLoad = true;

              this.oIndex = this.oIndex+1
              this.line = this.getLineNumber(
                this.lineList[this.oIndex].point_list,
              );
              this.list = this.getListNumber(
                this.lineList[this.oIndex].point_list,
              );
              this.model_url = this.lineList[this.oIndex].model_url;
              this.scene.remove(line);
              this.scene.remove(aline);
              this.scene.remove(end);
              this.scene.remove(user2);
              


              axios.post(
                'https://aipark.meilibaobiao.com/aipark/V1.0.0/query_park_status_list',
                {
                  data: {
                    ent_id:this.lineList[this.oIndex].point_list[0].ent_id,
                    build_id:this.lineList[this.oIndex].point_list[0].build_id,
                    floor_id:this.lineList[this.oIndex].point_list[0].floor_id,
                    area_id:this.lineList[this.oIndex].point_list[0].area_id,
                  },
                },
              )
              .then((response) => {
                if (response.data.code == 200) {
                  this.parkList = response.data.body.data;
                  this.createBox(this.scene);
                }
              })
              .catch((error) => {
                console.log(error);
              });
            } 
          }

        })
        .catch((error) => {
          console.log(error);
        });
      }
      
      return
      



      return
      timer = setInterval(() => {
          if (list[a] == undefined) {
            clearInterval(timer); // 结束
            console.log('list[a] == undefined');
            return;
          }
          let x = list[a].x; // 人物X
          let y = list[a].y; // 人物Y
          let nextIndex = this.line.findIndex((item) => item.show == false);
          // console.log(nextIndex);
          if (nextIndex == -1) {
            clearInterval(timer); // 结束
            console.log('nextIndex == -1');
            return;
          }
          let next = this.line[nextIndex]; // 下一个目标点 [0]==x [2] == y
          let next1 = this.line[nextIndex + 1];
          let last = nextIndex == 0 ? this.line[nextIndex] : this.line[nextIndex - 1];
          let resArr = this.line.filter((item) => item.show == false);
          // console.log(nextIndex,next,last)
          let long = Math.sqrt(
            Math.pow(last.x - x, 2) + Math.pow(last.y - y, 2),
          );

          let long2 = Math.sqrt(
            Math.pow(next.x - x, 2) + Math.pow(next.y - y, 2),
          );
          // console.log(long, long2, a, 'last');
         
          this.line[next.oIndex].show = true;

          let type = this.$route.query.type;
          if(next.direct!=null&&next.direct!=0&&type!=2&&type!=3){
            
            let ent_id = this.$route.query.ent_id;
            let build_id = this.$route.query.build_id;
            let floor_id = this.$route.query.floor_id;
            let area_id = this.$route.query.area_id;
            
             axios.post(
              'https://aipark.meilibaobiao.com/aipark/V1.0.0/query_car_park_if_used',
              {
                data: {
                  ent_id,
                  build_id,
                  floor_id,
                  area_id,
                  park_id:this.park_id
                },
              },
            )
            .then((response) => {
              if (response.data.code == 200) {
                if(response.data.body.data.status==1){
                  this.showReload = true
                  clearInterval(timer); // 结束
           
                  
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });



          }
          // this.distance = this.line[next.oIndex].title;

          this.camera.position.set(next.x, 100, next.y);
          // if (long > long2) {
          //   circleP.position.set(next.x, 3, next.y);
          //   this.line[next.oIndex].show = true;
          //   // this.distance = this.line[next.oIndex].title;

          //   this.camera.position.set(next.x, 100, next.y);
          // } else if (long < long2) {
          //   circleP.position.set(last.x, 3, last.y);
          //   this.line[last.oIndex].show = true;
          //   // this.distance = this.line[last.oIndex].title;

          //   this.camera.position.set(last.x, 100, last.y);
          // } else {
          //   circleP.position.set(last.x, 3, last.y);
          //   this.line[last.oIndex].show = true;
          //   // this.distance = this.line[last.oIndex].title;

          //   this.camera.position.set(last.x, 100, last.y);
          // }
          this.camera.lookAt(circleP.position);
          let residue = 0;

          this.camera.lookAt(circleP.position);
          let rx = this.getBinary(+last.x - +next.x);
          let ry = this.getBinary(+last.y - +next.y);

          this.onRotateZ(this.camera, rx, ry);

          let crossroads = resArr.filter((item) => item.direct != undefined)[0];

          let crossLong = Math.sqrt(
            Math.pow(crossroads.x - x, 2) + Math.pow(crossroads.y - y, 2),
          );

          let distance = ((crossLong * 3048) / 10000).toFixed();
          // console.log(distance,crossLong)
          if (distance == 0) {
            this.distance = this.coordinate[crossroads.direct];
          } else {
            if (crossroads.direct == 0) {
              this.distance = `${distance}米后${
                this.coordinate[crossroads.direct]
              }`;
            } else {
              if (+crossroads.direct % 2 === 0) {
                this.distance = `${distance}米后向${
                  this.coordinate[crossroads.direct]
                }行驶`;
              }
              if (+crossroads.direct % 2 === 1) {
                this.distance = `${distance}米后${
                  this.coordinate[crossroads.direct]
                }`;
              }
            }
          }
          residue = 0
          if (resArr.length) {
            for (let i = 0; i < resArr.length; i++) {
              if (i == 0) {
                // console.log('第一个')
                let number = resArr[i];
                residue += Math.sqrt(
                  Math.pow(number.x - x, 2) + Math.pow(number.y - y, 2),
                );
              } else if (i == resArr.length - 1) {
                // console.log('最后一个')
                let number = resArr[i];
                residue += Math.sqrt(
                  Math.pow(number.x - x, 2) + Math.pow(number.y - y, 2),
                );
              } else {
                // console.log(resArr[i],resArr[i+1])
                // console.log(`第${i+1}个`)
                // let number = resArr[i];
                // let number2 = resArr[i + 1];
                // residue += Math.sqrt(
                //   Math.pow(number.x - number2.x, 2) + Math.pow(number.y - number2.y, 2),
                // );
              }
            }
          }

          
           this.residue= ((residue * 3048) / 10000).toFixed();
          // console.log(this.residue,'residueresidueresidue')
          if (this.residue == 0) {
            console.log('终点');
            let line = scene.getObjectByName('line');
            let end = scene.getObjectByName('end');
            let user = scene.getObjectByName('user');

            

            console.log(this.line[this.line.length - 1]);
            if (this.line[this.line.length - 1].direct == '0') {
              // scene.remove(line);
              // scene.remove(end);
              document.title = '导航已结束';
              this.showClose = true;
            } else if (this.line[this.line.length - 1].direct == '9') {
              document.title = '正在前往下一层';
              this.showLoad = true;
              this.oIndex = this.oIndex+1
              this.line = this.getLineNumber(
                this.lineList[this.oIndex].point_list,
              );
              this.list = this.getListNumber(
                this.lineList[this.oIndex].point_list,
              );
              this.model_url = this.lineList[this.oIndex].model_url;
              
              scene.remove(line);
              scene.remove(end);
              scene.remove(user);
              console.log(this.lineList[this.oIndex],'[this.oIndex][0]')
               axios.post(
              'https://aipark.meilibaobiao.com/aipark/V1.0.0/query_park_status_list',
              {
                data: {
                  ent_id:this.lineList[this.oIndex].point_list[0].ent_id,
                  build_id:this.lineList[this.oIndex].point_list[0].build_id,
                  floor_id:this.lineList[this.oIndex].point_list[0].floor_id,
                  area_id:this.lineList[this.oIndex].point_list[0].area_id,
                },
              },
            )
            .then((response) => {
              if (response.data.code == 200) {
                this.parkList = response.data.body.data;
                this.createBox(scene);
              }
            })
            .catch((error) => {
              console.log(error);
            });
              
                
            } else if (this.line[this.line.length - 1].direct == '10') {
              document.title = '正在前往上一层';
              this.oIndex = this.oIndex+1
              this.line = this.getLineNumber(
                this.lineList[this.oIndex].point_list,
              );
              this.list = this.getListNumber(
                this.lineList[this.oIndex].point_list,
              );
              this.model_url = this.lineList[this.oIndex].model_url;
              scene.remove(line);
              scene.remove(end);
              scene.remove(user);
              this.createBox(scene);
            }
          }
          // scene.add(circleP);
          let offset = PointToLineDistance(
            x,
            y,
            last.x,
            last.y,
            next.x,
            next.y,
          );

          a++;
        }, 500);

    },
    clickBox(event) {
      let raycaster = new THREE.Raycaster();
      let mouse = new THREE.Vector2();
      //将鼠标点击位置的屏幕坐标转换成threejs中的标准坐标
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = 1 - (event.clientY / window.innerHeight) * 2;
      // console.log(mouse);

      raycaster.setFromCamera(mouse, this.camera);

      // 获取raycaster直线和所有模型相交的数组集合
      var intersects = raycaster.intersectObjects(this.scene.children);

      //将所有的相交的模型的颜色设置为红色
      for (var i = 0; i < intersects.length; i++) {
        // console.log(intersects[i]);
        // intersects[i].object.material.color.set(0xff0000);
      }
    },
    getLineNumber(list) {
      let newArr = [];
      list.map((item, index) => {
        let x = Number(item.x) / 304.8;
        let y = -Number(item.y) / 304.8;
        // console.log(x, y, item.x, item.y);
        newArr.push({
          x: x,
          y: y,
          point_id:item.point_id,
          show: false,
          oIndex: index,
          direct: item.direct,
        });
      });

      return newArr;
    },
    getListNumber(list) {
      let newArr = [];
      list.map((item) => {
        let x = Number(item.x) / 304.8;
        let y = -Number(item.y) / 304.8;

        newArr.push({ x: x, y: y });
      });
      // console.log(newArr)
      return newArr;
    },
    getListArr(list) {
      let subNum = (parseInt(list[0].x) - parseInt(list[1].x)) / 3;
      let arr = [];
      let nx = list[0].x;
      let ny = list[0].y;
      for (let i = 0; i < subNum; i++) {
        arr.push({ x: nx, y: ny });
        nx = nx - 3;
      }
      return arr;
    },

    onUserRotateZ(user, rx, ry) {
      
      if (rx == 1 && ry == 1) {
        user.rotation.z = (((45) * Math.PI) / 180);
      }
      if (rx == -1 && ry == -1) {
        user.rotation.z = (((225) * Math.PI) / 180);
      }
      if (rx == -1 && ry == 1) {
        user.rotation.z = (((315) * Math.PI) / 180);
      }
      if (rx == 1 && ry == -1) {
        user.rotation.z = (((135) * Math.PI) / 180);
      }

      if (rx == 1 && ry == 0) {
        user.rotation.z = (((90) * Math.PI) / 180);
      }
      if (rx == -1 && ry == 0) {
        user.rotation.z = (((270) * Math.PI) / 180);
      }

      if (rx == 0 && ry == 1) {
        user.rotation.z = (((0) * Math.PI) / 180);
      }
      if (rx == 0 && ry == -1) {
        user.rotation.z = (((180 * Math.PI) / 180));
      }
    },

    onRotateZ(camera, rx, ry) {
      
      if (rx == 1 && ry == 1) {
        camera.rotateZ(((45) * Math.PI) / 180);
      }
      if (rx == -1 && ry == -1) {
        camera.rotateZ(((225) * Math.PI) / 180);
      }
      if (rx == -1 && ry == 1) {
        camera.rotateZ(((315) * Math.PI) / 180);
      }
      if (rx == 1 && ry == -1) {
        camera.rotateZ(((135) * Math.PI) / 180);
      }

      if (rx == 1 && ry == 0) {
        camera.rotateZ(((90) * Math.PI) / 180);
      }
      if (rx == -1 && ry == 0) {
        camera.rotateZ(((270) * Math.PI) / 180);
      }

      if (rx == 0 && ry == 1) {
        camera.rotateZ(((0) * Math.PI) / 180);
      }
      if (rx == 0 && ry == -1) {
        camera.rotateZ(((180) * Math.PI) / 180);
      }
    },

    getBinary(num) {
      if (num > 0) {
        return 1;
      } else if (num < 0) {
        return -1;
      } else {
        return 0;
      }
    },
    calcM(num) {
      return ((parseInt(num) * 304.8) / 1000).toFixed(2);
    },
  },
  watch: {
    currentRouteType: function (v, o) {
      location.reload();
    },
    position: function (v, o) {
      
      if(this.pageInit==false && this.voiceMsgStatus!=0){
        this.pageInit = true
        this.init()
      }else{
        // console.log(new Date().getTime())
        // if(timer==null&&this.showClose ==false){
        //   clearInterval(timer)
        //   timer = setInterval(()=>{

        //     this.updataPosition()
          
        //   },500)
        // }
        // numberTime++ 
        
        
      }
    },
  },
};
</script>
<style>
@import './home.css'
</style>