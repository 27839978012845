import { createStore } from 'vuex'
let i = 0;
let arr = [
  {
    buildId: "204513",
    floorNo: "Floor1",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: 28535,
    yMillimeter: 40000,
  },
  
  {
    buildId: "204513",
    floorNo: "Floor1",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: 31035,
    yMillimeter: 40000,
  },
  {
    buildId: "204513",
    floorNo: "Floor1",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: 39678,
    yMillimeter: 40000,
  },
  {
    buildId: "204513",
    floorNo: "Floor1",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: 43678,
    yMillimeter: 40000,
  },
  {
    buildId: "204513",
    floorNo: "Floor1",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: 50678,
    yMillimeter: 40000,
  },
  {
    buildId: "204513",
    floorNo: "Floor1",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: 55678,
    yMillimeter: 40000,
  },
  {
    buildId: "204513",
    floorNo: "Floor1",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: 60678,
    yMillimeter: 40000,
  },
  {
    buildId: "204513",
    floorNo: "Floor1",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: 71535,
    yMillimeter: 40000,
  },{
    buildId: "204513",
    floorNo: "Floor1",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: 77383,
    yMillimeter: 40000,
  },{
    buildId: "204513",
    floorNo: "Floor1",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: 80000,
    yMillimeter: 40000,
  },{
    buildId: "204513",
    floorNo: "Floor1",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: 83000,
    yMillimeter: 40000,
  },{
    buildId: "204513",
    floorNo: "Floor2",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: 73281,
    yMillimeter: 40627,
  }
  ,{
    buildId: "204513",
    floorNo: "Floor2",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: 62282,
    yMillimeter: 40627,
  }
  ,{
    buildId: "204513",
    floorNo: "Floor2",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: 51281,
    yMillimeter: 40627,
  },{
    buildId: "204513",
    floorNo: "Floor2",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: 40281,
    yMillimeter: 40627,
  },{
    buildId: "204513",
    floorNo: "Floor2",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: 30281,
    yMillimeter: 40627,
  },{
    buildId: "204513",
    floorNo: "Floor2",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: 10281,
    yMillimeter: 40627,
  },{
    buildId: "204513",
    floorNo: "Floor2",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: 281,
    yMillimeter: 40627,
  },{
    buildId: "204513",
    floorNo: "Floor2",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: -10281,
    yMillimeter: 40627,
  },{
    buildId: "204513",
    floorNo: "Floor2",
    latitude: 31.266133894944183,
    longitude: 120.72969723093473,
    timestampMillisecond: 1650422645217,
    userId: "15264086654",
    xMillimeter: -20281,
    yMillimeter: 40627,
  }
]
export default createStore({
  state: {
    position:{}
  },
  getters: {
  },
  mutations: {
    SET_POSITION(state, position) {
      
      // i++
      // if(arr[parseInt(i/10)]){
      //   position = arr[parseInt(i/10)]
        let x = Number(position.xMillimeter)
        let y = Number(position.yMillimeter)
        // console.log(position)
        state.position = {
          ...position,
          yMillimeter: -y,
          x: x / 304.8,
          y: -y / 304.8,
        }
      // }
    }
  },
  actions: {
    setPosition({commit}, status) {
      
      commit("SET_POSITION", status)
    }
  },
  modules: {
  }
})
