<template>
  <router-view/>
</template>
<script>

import axios from 'axios';
import {mapState,mapActions} from 'vuex';
import Vconsole from 'vconsole'
export default {
  name: 'APP',
  data() {
    return {
     
    };
  },
  components: {

  },
  computed: {
   
  },
  mounted: function () {
    // new Vconsole()
    this.createJoySuchSDK();
    this.initJoySuchSDK();
    this.deviceOrientation();
    // setTimeout(()=>{
    //   this.setPosition({
    //     buildId: "204574",
    //     floorNo: "FloorB3",
    //     latitude: 39.99769490609464,
    //     longitude: 116.42757166766796,
    //     timestampMillisecond: 1655287464246,
    //     userId: "15264086654",
    //     xMillimeter: 106089,
    //     yMillimeter: 141732,
    //   })
    // },1000)
  },
  methods: {
    ...mapActions({
      	setPosition:'setPosition',
       
      }),
     getQueryString(name) {
        var reg = new RegExp("(^|&|[?])" + name + "=([^&]*)(&|$)")
        var r = window.location.href.substr(1).match(reg)
        var newName = (r != null ? r[2] : "")
        return newName
    },
    // 创建SDK
    createJoySuchSDK () {
      console.log(this.$route,'.$route')
      window.joysuchlocatesdk = new jslocate({
        // 建筑ID
        buildId: this.getQueryString('build_id'),
        // 微信OPenId
        openID: this.getQueryString('user_id'),
        // 平滑开关，默认开启，false:使用ws结果直接定位
        pdrSmoothOpen: false,
        // 平滑帧数，平滑关闭时无效
        pdrFrameNum: 11,
        // 每秒步长（毫米）
        stepLong: 1600,
        // 是否预发布环境
        isWsUrlPre:true,
        wsUrl:"wss://api.joysuch.com:46000/websocket/"
      });
      window.joysuchlocatesdk.start();
    },

    // 获取定位结果初始化
    initJoySuchSDK: function () {
      var that = this;

      if (window.joysuchlocatesdk == null) {
        return;
      }
      window.joysuchlocatesdk.requestLocate(
        function (requestCall) {
          // 暂时废止

        },
        function (resultCall) {
          // 定位结果回调,实现地图定位打点效果

          // 此处将定位结果打印到表格中
          if (resultCall.errorCode == 0) {
            var item = resultCall.data[0];
            // console.log(new Date().getTime(),'index')
            
            that.setPosition(item)
            // item.time = vm.timestampToTime(item.timestampMillisecond);
            // //数据放入表格 todo
            // vm.table.data.push(item);
            // that.$refs.tableList.bodyWrapper.scrollTop =that.$refs.tableList.bodyWrapper.scrollHeight;
            // //显示点位
            // //TODO  (item.xMillimeter / 1000 * 47.21 * 0.25).toFixed(2))
            // //把返回的毫米装换为x，y坐标（47.21为坐标比例，0.25为地图缩放比例，根据实际修改），保留两位小数
            // vm.addPiot(parseInt((item.xMillimeter / 1000 * 47.21 * 0.25).toFixed(2)), parseInt((item.yMillimeter /
            //   1000 *
            //   47.26 * 0.25)
            //   .toFixed(2)));

          } else {
            alert("定位结果失败：" + resultCall.errorMsg);
            console.log("定位结果失败：" + resultCall.errorMsg);
          }

          /****** resultCall对象说明 *******
          resultCall： {
            errorCode: 0,
            errorMsg: '',
            data: {
              // 建筑ID
              "buildId": "200036",
              // 楼层编号
              "floorNo": "Floor5",
              // 时间戳
              "timestampMillisecond": 1603785310751,
              // 用户ID，即openID
              "userId": "oXgCD4pL6NDU6nEAjyamqm0zR5zQ",
              // X 坐标
              "xMillimeer": 22853,
              // Y 坐标
              "yMillimeter": 22486
              }
          }

          ****** resultCall对象说明 *******/
        },
        function (errorCall) {
          //错误信息回调，比如，是否开启蓝牙以及定位权限定位权限
        },
        function (resultFlag) {
          //小程序蓝牙,罗盘状态
        },
        function (obj) {
          //暂时废止
        }
      );
    },

    // 传感器器事件监听
    deviceOrientation: function () {
      
      if (window.DeviceOrientationEvent) {
        if (browser.versions.ios) {
          window.addEventListener(
            "deviceorientation",
            this.orientationHandler,
            false
          );
        } else if (browser.versions.android) {
          window.addEventListener(
            "deviceorientationabsolute",
            this.orientationHandler,
            true
          );
        }
      }
      window.addEventListener(
        "devicemotion",
        event => {
          if (window.joysuchlocatesdk) {
            window.joysuchlocatesdk.setDevicemotion(event);
          }
        },
        false
      );
    },

    // 事件处理
    orientationHandler: function (event) {
      var that = this;
      let webkitCompassHeading;
      let direction;
      let alphaValue = event.alpha;
      let betaValue = event.beta;
      let gammaValue = event.gamma;

      if (browser.versions.ios) {
        webkitCompassHeading = event.webkitCompassHeading;
        direction = webkitCompassHeading;
      } else if (browser.versions.android) {
        webkitCompassHeading = event.alpha;
        direction = 360 - webkitCompassHeading;
      }
      if (window.joysuchlocatesdk) {
        let webkit = {
          webkitCompassHeading: direction,
          alpha: alphaValue,
          beta: betaValue,
          gamma: gammaValue
        };
        // axios.post('https://aipark.meilibaobiao.com/aipark/V1.0.0/test_logs',{data: {
        //   user_id:that.getQueryString('user_id'),
        //   value:JSON.stringify(webkit),
        //   type:'3'
        // }})

        window.joysuchlocatesdk.setOrientationHandler(webkit);
      }
    },
    //时间戳转时间
    timestampToTime: function (timestamp) {
      var date = new Date(); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
      strDate = Y + M + D + h + m + s;
      return strDate;
    },
    
  },
  
		
}
</script>
<style lang="less">
body {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
